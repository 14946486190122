<template>
  <div>
    <div class="page-title">
      <router-link
        class="btn btn-primary field-submit float-right"
        to="/dashboard/create_application/hmac_auth"
      >
        {{ $t("pages.applications.create_application") }}
      </router-link>
      <h1>{{ $t("pages.applications.title") }}</h1>
    </div>
    <div class="page-content">
      <div class="menu-container">
        <div class="menu-description">
          <h2>{{ $t("pages.applications.hmac_auth_application_title") }}</h2>
        </div>
        <table class="table side-border-none top-border-none">
          <thead>
            <tr>
              <th scope="col" width="50%">
                <span class="pr-2 font-title">{{
                  $t("pages.applications.name")
                }}</span>
              </th>
              <th scope="col" width="35%">
                <span class="pr-2 font-title">{{
                  $t("pages.applications.creation_date")
                }}</span>
              </th>
              <th scope="col" width="15%"></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="hmacAuthApplication in $store.state.applications
                .hmacAuthApplications"
              :key="hmacAuthApplication.id"
            >
              <td scope="row">{{ hmacAuthApplication.attributes.name }}</td>
              <td>
                {{ formatDate(hmacAuthApplication.attributes.created_at) }}
              </td>
              <td>
                <a
                  :href="'/dashboard/applications/' + hmacAuthApplication.id"
                  >{{ $t("pages.applications.show") }}</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { FETCH_APPLICATIONS } from "@/store-types";
import moment from "moment";
import { SHOW_TOAST } from "@/store-types";
import { TYPE as ToastType } from "vue-toastification";

export default defineComponent({
  mounted() {
    this.loadApplication();
  },
  methods: {
    formatDate(created_at: string): string {
      return moment(String(created_at)).format("MMMM Do, YYYY");
    },
    async loadApplication() {
      try {
        await this.$store.dispatch(FETCH_APPLICATIONS);
      } catch (err) {
        this.handleError(err);
      }
    },
    handleError(err: Error | any) {
      const message = err.response
        ? err.response.data.message
        : "Something went wrong. Try again";

      this.$store.dispatch(SHOW_TOAST, {
        message: message,
        type: ToastType.ERROR,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_color";

.page-title {
  padding: 22px 24px;
  background: #f3f5f7;

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: $color-black;
  }

  a {
    color: #f3f5f7;
    text-decoration: none;
    margin: 0;
  }
}

.page-content {
  border-top: 1px solid $color-gray-smoke;
  background: $color-white;
  min-height: calc(100vh - 160px);
  padding: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  .menu-container {
    h2 {
      color: $color-black;
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      color: $color-black;
    }

    .app-title {
      color: $color-black;
      font-size: 14px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      color: $color-black;
    }

    .menu-description {
      margin: 8px 0 28px 0;
    }

    .group {
      display: flex;
      justify-content: space-between;
      margin: 24px 0;
    }
  }
}
</style>
