
import { defineComponent } from "vue";
import { FETCH_APPLICATIONS } from "@/store-types";
import moment from "moment";
import { SHOW_TOAST } from "@/store-types";
import { TYPE as ToastType } from "vue-toastification";

export default defineComponent({
  mounted() {
    this.loadApplication();
  },
  methods: {
    formatDate(created_at: string): string {
      return moment(String(created_at)).format("MMMM Do, YYYY");
    },
    async loadApplication() {
      try {
        await this.$store.dispatch(FETCH_APPLICATIONS);
      } catch (err) {
        this.handleError(err);
      }
    },
    handleError(err: Error | any) {
      const message = err.response
        ? err.response.data.message
        : "Something went wrong. Try again";

      this.$store.dispatch(SHOW_TOAST, {
        message: message,
        type: ToastType.ERROR,
      });
    },
  },
});
